import React, { useMemo } from 'react';
import { getTemplatePreviewImage } from 'components/template-management/utilities';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Illustration from 'components/ui-components-cape/Illustration';
import { TemplateAsset } from '../../../types/template-management.type';
import TemplateManagementAssetTilePreviewSlideshow from './preview-slideshow';
import TemplateManagementAssetTilePreviewImage from './preview-image';

interface Props {
    template: TemplateAsset;
    delayBeforeStart?: number;
    hovered?: boolean;
}

const TemplateManagementAssetTilePreviewSwitch = ({ template, delayBeforeStart, hovered }: Props) => {
    const { assetsPlaceholder } = useAssetManagementConfigContext();
    const thumbnailUrl = useMemo(() => getTemplatePreviewImage(template), [template]);

    if (template.data.image && template.data.image.length) return <TemplateManagementAssetTilePreviewImage url={template.data.image} />;

    if (Array.isArray(template.data.thumbnail) && template.data.thumbnail.length > 1)
        return (
            <TemplateManagementAssetTilePreviewSlideshow
                images={template.data.thumbnail.map((t) => t.url)}
                start={hovered}
                delayBeforeStart={delayBeforeStart}
            />
        );
    if (thumbnailUrl) return <TemplateManagementAssetTilePreviewImage url={thumbnailUrl} />;

    return assetsPlaceholder && <Illustration size="large" illustration={assetsPlaceholder} />;
};

export default TemplateManagementAssetTilePreviewSwitch;
